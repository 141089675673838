import React from 'react'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import PageContainer from '@system/page-container'
import rightTriangle from '@images/blue_gradient_triangle_right.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  top: {
    zIndex: '0',
  },
  bottom: {
    zIndex: '0',
    background: 'linear-gradient(106.52deg, #1858A8 -3.9%, rgba(24, 88, 168, 0) 52.24%), #182F4E',
  },
  moduleContainer: {
    zIndex: '1',
  },
  triangle: {
    zIndex: '0',
    height: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    '& img': {
      position: 'relative',
      top: '-20rem',
      height: '30rem',
      [theme.breakpoints.down('sm')]: {
        top: '-10rem',
        height: '15rem',
      },
    },
  },
}))

const ConnectedTwoColumnHighlights = ({blok}) => {
  const classes = useStyles(blok)
  return ( 
    <SbEditable content={blok}>
      <PageContainer>
        <Box className={classes.root}>
          <Box className={classes.top}>
            <Box className={classes.moduleContainer}>
              {renderBloks(blok.moduleOne, {isConnected: true})}
            </Box>
          </Box>
          <Box className={classes.triangle}>
            <img src={rightTriangle}></img>
          </Box>
          <Box className={classes.bottom}>
            <Box className={classes.moduleContainer}>
              {renderBloks(blok.moduleTwo, {isConnected: true})}
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </SbEditable>
   )
}
 
export default ConnectedTwoColumnHighlights
